import React from 'react'
import { Link, graphql } from 'gatsby'
import { EmblaOptionsType } from 'embla-carousel'
import Layout from '../components/layout'
import EmblaCarousel from '../components/EmblaCarousel'
import controls_slide from '../../content/assets/controls_slide.jpg'
import ipc_slide from '../../content/assets/ipc_slide.jpg'
import wf_slide from '../../content/assets/wf_slide.jpg'
import controls_logo from '../../content/assets/controls_logo.svg'
import ipc_logo from '../../content/assets/ipc_logo.svg'
import wf_logo from '../../content/assets/wf_logo.svg'

class Index extends React.Component<GatsbyProps> {
    render() {
        const { data } = this.props
        const siteTitle = data.site.siteMetadata.title
        const pages = data.allMdx.edges
        const opt: EmblaOptionsType = {
            loop: true
        }
        const logos = [ipc_logo, controls_logo, wf_logo]
        return (
            <Layout location={this.props.location}>
                <EmblaCarousel options={opt} dots={false} AUTOPLAY_INTERVAL={4000}>
                    <img src={controls_slide} alt="" />
                    <img src={ipc_slide} alt="" />
                    <img src={wf_slide} alt="" />
                </EmblaCarousel>
                <div className="text-center bg-secondary p-1 shadow-sm">PRODUCATORI</div>
                <main className="container mx-auto p-4">
                    <div className="flex justify-center items-center">
                        {logos.map((src, i) => (
                            <img
                                style={{
                                    paddingLeft: '3%',
                                    paddingRight: '3%'
                                }}
                                className={`w-1/4 lg:w-1/5 border-secondary ${
                                    i !== 0 && 'border-l-2'
                                }`}
                                src={src}
                                alt=""
                            />
                        ))}
                    </div>
                    {pages.map(({ node }, i) => {
                        const path = node.fields.slug.replace(/\/$/g, '')
                        const title = node.frontmatter.title || path
                        return (
                            <Link to={path}>
                                <article
                                    className={`hover:shadow shadow-sm rounded-sm p-3 my-2 border-b-2 border-secondary bg-opacity-25`}
                                    key={path}
                                >
                                    <header>
                                        <h3 className="mb-1">{title}</h3>
                                        <small>{node.frontmatter.date}</small>
                                    </header>
                                    <section>
                                        <p
                                            className="mb-1"
                                            dangerouslySetInnerHTML={{
                                                __html: node.frontmatter.description || node.excerpt
                                            }}
                                        />
                                    </section>
                                </article>
                            </Link>
                        )
                    })}
                </main>
            </Layout>
        )
    }
}

export default Index

export const pageQuery = graphql`
    query {
        site {
            siteMetadata {
                title
            }
        }
        # allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
        allMdx(limit: 1000) {
            edges {
                node {
                    excerpt
                    fields {
                        slug
                    }
                    frontmatter {
                        # date(formatString: "MMMM DD, YYYY")
                        title
                        # description
                    }
                }
            }
        }
    }
`
